import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';

export const Title = tw.h2`font-display text-3xl font-bold mb-4 tracking-wide`;
export const Wrapper = tw.div`w-full flex justify-start mt-6 -mb-6`;

export const LinkTo = tw(
  Link
)`font-display font-bold text-base tracking-wide py-3 px-6 rounded text-white bg-primary-500 hover:bg-primary-600`;

export const Text = tw.p`text-gray-700 mb-4`;

export const Container = tw.section`w-full mx-auto text-center py-16 md:py-24 px-8 md:px-16 bg-primaryLight`;

export const Header = tw.h2`font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center`;

export const SubTitle = tw.h3`font-display mb-4 text-xl lg:text-3xl leading-tight`;

export const EventsContainer = tw.div`w-full flex flex-col justify-start max-w-2xl mx-auto py-16 md:py-24 px-8 md:px-16`;

export const EventsSubTitle = tw.span`mb-1 block md:text-center`;
export const EventsTitle = tw.h2`font-display text-3xl font-bold mb-4 tracking-wide md:text-center`;
