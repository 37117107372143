import React from 'react';
import { graphql, Link } from 'gatsby';

import { ImageTextRow } from '../components/ImageTextRow/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import Section from '../components/Section/';
import { Testimonials } from '../components/Testimonials/';
import NewsletterForm from '../components/NewsletterForm';
import { Badges } from '../components/Badges/';
import { AnchorLinks } from '../components/AnchorLinks/';
import getEnvVars from '../environment';
import {
  Title,
  Wrapper,
  LinkTo,
  Text,
  Container,
  Header,
  SubTitle,
  EventsContainer,
  EventsSubTitle,
  EventsTitle,
} from '../styles/partner-werden';

const { webAppUrl } = getEnvVars();

const B2BPage = ({
  data: {
    Hero: HeroImage,
    B2BIntroduction,
    Business1,
    Business2,
    Business3,
    Business4,
    Business5,
    Sprint1,
    Sprint2,
    Sprint3,
    Sprint4,
    Sprint5,
    Sprint6,
  },
}) => {
  return (
    <PageLayout>
      <SEO title="Unsere Angebote für Dein Unternehmen" />
      <Hero
        image={HeroImage}
        title={
          <>
            Partner werden!
            <br />
            Der Boost für Dein Geschäft.
          </>
        }
        subTitle="Vorteile für Deinen Online-Shop und Dein Ladenlokal"
        fullscreen>
        <a
          href={`${webAppUrl}signup`}
          target="_blank"
          rel="noreferrer"
          className="font-display font-bold font-xl tracking-wide block py-3 px-6 rounded text-white bg-primary-500 hover:bg-primary-600">
          Jetzt registrieren
        </a>
      </Hero>
      <AnchorLinks
        title="Unsere Produkte:"
        links={[
          { title: 'Business', anchor: '/partner-werden#business' },
          { title: 'Sprint', anchor: '/partner-werden#sprint' },
          { title: 'API', anchor: '/partner-werden#api' },
          { title: 'Events', anchor: '/partner-werden#events' },
        ]}
      />
      <Section fullWidth>
        <ImageTextRow leftImageFluid={B2BIntroduction} position="left" fullWidth>
          <p className="text-gray-700 text-lg md:text-xl">
            Für jeden Pott gibt es einen Deckel. Wir minimieren für den eCommerce die Servicekosten
            um bis zu 25%, optimieren die Logistik auf der letzten Meile und reduzieren
            Paket-Retouren. Und mithilfe unserer Click & Collect-Features ermöglichen wir lokalen
            Händlern z. B. die persönliche Produktübergabe an Kunden - egal ob das Ladenlokal
            geschlossen ist, oder nicht.
            <br />
            <br />
            DropFriends bietet Dir und Deinem Unternehmen für jede Situation die passende Lösung,
            sodass Du Dein Geschäft auf die nächste Stufe bringen kannst!
          </p>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Business1} position="right" fullWidth id="business">
          <Title>DropFriends Business</Title>
          <Text>
            Mit DropFriends BUSINESS werden Deine Einzelhandels-Filialen zum perfekten
            Paketübergabeort (DropPoint) für Dein Stadtviertel. Filialteams können einfach und
            sicher eintreffende Paketsendungen und Click & Collect Einkäufe per Smartphone
            dokumentieren und verwalten. Du bestimmst wieviele Sendungen maximal vorgehalten werden
            sollen, um die Filialkapazitäten zu schonen. Die Sendungseigentümer werden in Echtzeit
            über die Paketaufbewahrung durch Deine Filiale informiert, sodass eine tagesgleiche
            Abholung forciert wird. DropFriends übernimmt dabei die gesamte Kommunikation zwischen
            Dir als DropPoint und den Abholern.
          </Text>
          <Wrapper>
            <LinkTo to="/paketshop-werden">Jetzt anschauen</LinkTo>
          </Wrapper>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Business2} position="left" fullWidth>
          <span className="mb-2">DropFriends Business</span>
          <Title>Jedes Paket wird zum zahlenden Kunden</Title>
          <Text>
            Durch die erhöhte Personenfrequenz in Filialen sowie einem individuellen Gutschein
            Programm für Kunden werden Paketabholer zu echten Kunden. So steigert sich Dein Umsatz
            und die Bindung zu Deiner Marke.
          </Text>
          <Wrapper>
            <LinkTo to="/paketshop-werden">Jetzt anschauen</LinkTo>
          </Wrapper>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Business3} position="right" fullWidth>
          <span className="mb-2">DropFriends Business</span>
          <Title>Echtes Teamwork</Title>
          <Text>
            Mithilfe der Online-Software können einzelne Ladenlokale und selbst Filialen zentral und
            flexibel als DropPoint (de)aktiviert und die entsprechenden lokalen Teams verwaltet
            werden. Deine Mitarbeiter nutzen die intuitive DropFriends Smartphone App zum Sendungs-,
            Identifizierungs- und Übergabemanagement. Natürlich mit intuitiver Rechteverteilung.
          </Text>
          <Wrapper>
            <LinkTo to="/paketshop-werden">Jetzt anschauen</LinkTo>
          </Wrapper>
        </ImageTextRow>

        <ImageTextRow
          leftImageFluid={Business4}
          // rightImageMobileLast
          rightImageFluid={Business5}
          fullWidth
        />

        <Testimonials
          className="bg-gray-200"
          testimonials={[
            {
              testimonial: (
                <>
                  Funktioniert!
                  <br />
                  In Köln gibts enorm viele Annahmestellen und ich habe meinen gefunden. Probiert‘s
                  aus!
                </>
              ),
              name: 'Katrin',
              source: 'Play Store',
            },
            {
              testimonial:
                'Ich kann die App wärmstens empfehlen. Bisher hat alles super geklappt und es kommen dauernd neue Orte hinzu, wo ich was hin liefern kann. Nach dem ganzen Stress, den ich bisher in meinem Leben mit Packstationen hatte, bin ich echt erleichtert, dass sich jemand um eine bessere Lösung bemüht hat.',
              name: 'Dennis ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super!
                  <br />
                  Dank der App fühle ich mich bzw. meine Pakete gut aufgehoben. Das hier braucht
                  jeder und mein Gott, wie einfach alles war. Einfach genial!!!
                </>
              ),
              name: 'Engin ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super App!
                  <br />
                  Läuft stabil und tut was sie soll.
                </>
              ),
              name: 'Chris ',
              source: 'Play Store',
            },
            {
              testimonial:
                'Finde, das ist eine sehr clevere App, viel Potenzial ist da! Ich habe schon einen DropPoint aufgemacht. Finde die Idee super!',

              name: 'Phillip ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super App!
                  <br />
                  Unbedingt ausprobieren.
                </>
              ),
              name: 'Oleg ',
              source: 'Play Store',
            },
          ]}
        />

        <ImageTextRow leftImageFluid={Sprint1} position="left" fullWidth id="sprint">
          <Title>DropFriends Sprint</Title>
          <Text>
            DropFriends Sprint ist eine Online-Software, die Deinem Unternehmen die Möglichkeit gibt
            einen eigenen Multi-Kanal-Handel einzurichten – mit wenigen Klicks. Das heißt, dass wir
            Deinen Online- und Offlinehandel verbinden und ermöglichen so eine Reduzierungen Deiner
            Kosten, Verringerung Deines Aufwands sowie der erheblichen Senkung Deines gewerblichen
            CO2-Fußabdrucks.
          </Text>
          <Wrapper>
            <LinkTo to="/pakete-verschicken">Jetzt anschauen</LinkTo>
          </Wrapper>
        </ImageTextRow>

        <ImageTextRow rightImageFluid={Sprint2} position="right" fullWidth>
          <span className="mb-2">DropFriends Sprint</span>
          <Title>Same Day Unpacking, statt Next Day Delivery</Title>
          <Text>
            Durch DropFriends SPRINT wird Deine online verkaufte Ware (Click & Collect) an Deine
            lokalen Kunden übergeben - bei einem für sie relevanten Standort. Wir nennen diese
            DropPoints. Deine Kunden erhalten ihren Einkauf unabhängig von den Öffnungszeiten Deines
            Ladenlokals. Sogar in der Nacht oder am Morgen. So bist Du dem eCommerce immer einen
            Schritt voraus.
          </Text>
          <Text>
            Zusätzlich sichert Dir DropFriends SPRINT relevante Werbeflächen auf der DropFriends
            Plattform, um Dir Deine digitale und relevante Laufkundschaft zu sichern.
          </Text>
          <Wrapper>
            <LinkTo to="/pakete-verschicken">Jetzt anschauen</LinkTo>
          </Wrapper>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Sprint3} position="left" fullWidth id="api">
          <span className="mb-2">DropFriends API</span>
          <Title>Direktintegration in Deinen Shop</Title>
          <Text css={{ marginBottom: '1.5rem !important' }}>
            Unsere technische Schnittstelle ermöglicht es Dir DropFriends direkt in Deinen
            Online-Shop oder Deine eigenen Systeme zu integrieren. Dadurch können Deine Kunden z.B.
            während ihres Einkaufs lieferdienstunabhängige Ablagestandorte in der Nähe wählen.
          </Text>
          <Wrapper>
            <LinkTo href="mailto:sales@dropfriends.com?subject:Anfrage zu DropFriends API">
              Kontaktiere uns
            </LinkTo>
          </Wrapper>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Sprint4} position="right" fullWidth>
          <span className="mb-2">DropFriends API</span>
          <Title>Deine Mitarbeiter und Kunden werden es lieben</Title>
          <Text css={{ marginBottom: '1.5rem !important' }}>
            Für Deine Mitarbeiter ergeben sich transparentere Prozesse und ein geringerer
            Serviceaufwand von bis zu 25 % durch ausbleibende Nachfragen und Beschwerden über den
            Aufenthalt, wie auch Paketzustände von Sendungen.
          </Text>
          <Text css={{ marginBottom: '1.5rem !important' }}>
            Sie können zusätzlich in ihrer gewohnten Softwareumgebung z.B. DropPoints buchen,
            Paketzustände nach Warenablagen einsehen und Paketübergaben verfolgen und für sich
            bewerten. Wir unterstützen Dich und Dein Team bei der Integration und beraten
            dahingehend gerne. Sprich uns diesbezüglich an.
          </Text>
          <Wrapper>
            <LinkTo href="mailto:sales@dropfriends.com?subject:Anfrage zu DropFriends API">
              Kontaktiere uns
            </LinkTo>
          </Wrapper>
        </ImageTextRow>

        <Container>
          <Header>Jetzt die DropFriends-App downloaden.</Header>

          <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>

          <Badges className="my-4" />

          <NewsletterForm />
        </Container>

        <ImageTextRow
          leftImageFluid={Sprint5}
          rightImageMobileLast
          rightImageFluid={Sprint6}
          fullWidth
        />

        <EventsContainer id="events">
          <EventsSubTitle>DropFriends Events</EventsSubTitle>
          <EventsTitle>Umsatzbooster für Großevents</EventsTitle>
          <p className="text-gray-700 mb-6 md:text-center">
            Für Messen und große Events, wie zum Beispiel Festivals oder Stadionveranstaltungen, ist
            DropFriends Events perfekt geeignet. Du buchst selbsterstellte Warenübergabe-Standorte
            auf Deinem Veranstaltungsgelände oder nutzt bereits existente DropPoints in der direkten
            Nachbarschaft. Produkt-, Ticket oder Dokumentübergaben werden so strukturiert geplant,
            sind sicher und werden ohne Wartezeiten für Deine Veranstaltungsbesucher gestaltet.
            Zusätzlich werden Deine Händler Vorort über Event-Apps oder zentrale Event-Online-Shops
            sichtbarer und profitieren so von steigenden Umsatzzahlen. Lange Lieferwege und
            Menschenansammlungen werden vermieden und die Besucher können sich entspannt, sowie ohne
            Einschränkungen auf das Event konzentrieren.
          </p>
          <div className="w-full flex justify-start md:justify-center mt-4">
            <LinkTo href="mailto:sales@dropfriends.com?subject:Anfrage zu DropFriends Events">
              Kontaktiere uns
            </LinkTo>
          </div>
        </EventsContainer>
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero: file(relativePath: { eq: "b2b-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    B2BIntroduction: file(relativePath: { eq: "b2b-image-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business1: file(relativePath: { eq: "b2b-business-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business2: file(relativePath: { eq: "b2b-business-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business3: file(relativePath: { eq: "b2b-business-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business4: file(relativePath: { eq: "b2b-business-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business5: file(relativePath: { eq: "b2b-business-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint1: file(relativePath: { eq: "b2b-sprint-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint2: file(relativePath: { eq: "b2b-sprint-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint3: file(relativePath: { eq: "b2b-sprint-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint4: file(relativePath: { eq: "b2b-sprint-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint5: file(relativePath: { eq: "b2b-sprint-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint6: file(relativePath: { eq: "b2b-sprint-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default B2BPage;
